.banner {
  width: "100%";
}

.ourBusiness {
  display: flex;
  width: 100%;
  height: 4.6875rem;
}

.ourBusiness .downloadBtn {
  cursor: pointer;
  width: 1.588542rem;
  height: 0.28125rem;
  margin-top: 3.640625rem;
  margin-left: 1.875rem;
  background: transparent;
}

.ourService {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 3.958333rem;
  background: #ffffff;
  padding: 0.520833rem 1.875rem;
}

.ourService .textContainer {
  width: 2.760417rem;
  height: 2.916667rem;
  display: flex;
  flex-direction: column;
}

.ourService .textContainer>.title {
  padding-top: 0.333333rem;
  color: #d61f38;
  font-size: 0.25rem;
  height: 0.833333rem;
  font-weight: bold;
}

.ourService .textContainer>.contentBox {
  font-size: 0.09375rem;
  font-weight: 400;
  color: #3d3d3d;
  flex: 1 1;
}

.ourService .textContainer>.contentBox>.content {
  width: 2.765625rem;
  height: 1rem;
  font-size: 0.09375rem;
  font-family: "MiSans-Regular, MiSans";
  font-weight: 400;
  color: #3d3d3d;
}

.ourService .textContainer>.footer {
  height: 0.020833rem;
  display: flex;
}

.ourService .textContainer>.footer>.slider {
  width: 0.15625rem;
  background: rgba(0,0,0,0.2);
  margin-right: 0.041667rem;
  cursor: pointer;
}

.ourService .textContainer>.footer>.slider.active {
  width: 0.3125rem;
  background: #d61f38;
}

.ourService .imageGrid {
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  width: 2.916667rem;
  height: 2.916667rem;
}

.ourService .imageGrid>.image {
  width: 1.458333rem;
  height: 1.458333rem;
  padding: 0 0.15625rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-items: center;
  text-align: center;
  font-size: 0.15625rem;
  color: #3d3d3d;
  color: #ffffff;
}

.ourService .imageGrid .optionbgColor {
  color: #ffffff;
}

.features {
  width: 100%;
  height: 4.6875rem;
}

.whyFinshellPay {
  width: 100%;
  height: 4.6875rem;
}

.partner {
  width: 100vw;
  height: 4.6875rem;
  background: url("~@/assets/img_wc/bg-img-1.png") no-repeat center/cover;
}

.partner>.title {
  padding-top: 0.708333rem;
  text-align: center;
  font-size: 0.25rem;
  font-weight: bold;
  line-height: 0.25rem;
  letter-spacing: 0em;
  color: #ffffff;
  margin-bottom: 0.682292rem;
}

.partner>.imgBox {
  width: 6.213542rem;
  margin: 0 auto;
}

.partner>.imgBox>div {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.380208rem;
}

.partner>.imgBox>div>img {
  cursor: pointer;
  width: 0.46875rem;
}

.growhiz {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 4.6875rem;
  background: url("../../assets/img_wc/index-ad-bg.png") no-repeat center/100% 100%;
}

.growhiz>.title {
  margin-top: 0.729167rem;
  font-size: 0.25rem;
  font-weight: bold;
  color: #ffffff;
}

.growhiz>.content {
  margin-top: 0.1875rem;
  font-size: 0.125rem;
  font-weight: 400;
  color: #ffffff;
}

.growhiz .infoCard {
  width: 6.25rem;
  background: #ffffff;
  margin-top: 0.53125rem;
  display: flex;
  justify-content: space-between;
  padding: 0.223958rem 0.15625rem 0.130208rem 0.15625rem;
}

.growhiz .infoCard>.item {
  width: 1.067708rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.growhiz .infoCard>.item>img {
  height: 1.041667rem;
}

.growhiz .infoCard>.item>.title {
  margin-top: 0.104167rem;
  font-size: 0.09375rem;
  font-weight: 400;
  color: rgba(0,0,0,0.8);
  line-height: 0.125rem;
  text-align: center;
}

.customerSay {
  width: 100%;
  height: 3.489583rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.customerSay>.title {
  margin-top: 0.625rem;
  font-size: 0.1875rem;
  font-weight: bold;
  color: #000000;
}

.customerSay>.sliderBox {
  margin-top: 0.3125rem;
  width: 20.625rem;
  height: 1.71875rem;
}

.customerSay>.sliderBox .slider {
  width: 6.875rem;
  height: 1.71875rem;
}

.customerSay>.sliderBox .slider>.item {
  width: 6.25rem;
  height: 1.46875rem;
  background: #ffffff;
  box-shadow: 0 0.020833rem 0.15625rem 0 rgba(108,145,112,0.1);
  border-radius: 0.041667rem 0.375rem 0.041667rem 0.375rem;
  display: flex;
  flex-direction: column;
  padding: 0.125rem 0.260417rem 0.1875rem 0.260417rem;
}

.customerSay>.sliderBox .slider>.item .stars {
  width: 100%;
  height: 0.083333rem;
  display: flex;
}

.customerSay>.sliderBox .slider>.item .stars>img {
  width: 0.083333rem;
  height: 0.083333rem;
  margin-right: 0.020833rem;
}

.customerSay>.sliderBox .slider>.item .content {
  margin-top: 0.125rem;
  height: 0.677083rem;
  font-size: 0.15625rem;
  font-weight: normal;
  color: #767676;
  line-height: 0.1875rem;
}

.customerSay>.sliderBox .slider>.item .userInfo {
  width: 100%;
  height: 0.25rem;
  display: flex;
  align-items: center;
}

.customerSay>.sliderBox .slider>.item .userInfo .avatar {
  width: 0.25rem;
  height: 0.25rem;
  background: #d8d8d8;
  border-radius: 50%;
}

.customerSay>.sliderBox .slider>.item .userInfo .user {
  margin-left: 0.083333rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.customerSay>.sliderBox .slider>.item .userInfo .user>.name {
  font-size: 0.104167rem;
  font-weight: bold;
  color: #000000;
}

.customerSay>.sliderBox .slider>.item .userInfo .user>.time {
  font-size: 0.083333rem;
  font-weight: 400;
  color: #889089;
}

.customerSay>.sliderBox :global .slick-dots {
  bottom: 0;
}