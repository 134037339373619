* {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
  font-size: 0.072917rem;
}

body,
p,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
dl,
li,
dt,
dd,
a {
  /* There are margins by default, which should be cleared */
  margin: 0;
  padding: 0;
  /*Font settings*/
  font-size: 0.072917rem;
  font-family: "Microsoft Yahei",sans-serif;
  /* Remove the origin of the list */
  list-style: none;
  /* Default mouse */
  cursor: default;
}

/*Optional*/

html,
body {
  width: 100%;
  height: 100%;
}

/*In-line block element*/

input,
img {
  margin: 0;
  padding: 0;
  border: 0 none;
  outline-style: none;
  vertical-align: bottom;
}

img {
  -webkit-user-select: none;
  user-select: none;
}

.clearfix {
  overflow: auto;
  zoom: 1;
}

/*In-line element*/

a,
a:active,
a:visited {
  /*Underline and color*/
  text-decoration: none;
  color: #ffffff;
  cursor: pointer;
}

a:hover {
  color: #ffffff;
}

textarea {
  /* Clear border */
  border: none;
  /* Clear contour */
  outline: none;
  /* Prevent text fields from being dragged randomly */
  resize: none;
}

i {
  /*Text style*/
  font-style: normal;
}

table {
  /*Border Merge*/
  border-collapse: collapse;
  border-spacing: 0;
}

/* Use dummy elements to clear float */

.clearfix::before,
.clearfix::after {
  content: "";
  height: 0;
  line-height: 0;
  display: block;
  visibility: none;
  clear: both;
}

.clearfix {
  *zoom: 1;
}

.fl {
  float: left;
}

.fr {
  float: right;
}

/* Edition heart*/

.w {
  width: 6.25rem;
  margin: 0 auto;
}

/*Optional*/

/*The configuration of radio box and check box is generally separate*/

input[type="radio"],
input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  display: none;
}

label {
  display: inline-block;
  cursor: pointer;
}

label input[type="radio"]:checked~i,
label input[type="checkbox"]:checked~i {
  color: #38d6ff;
}

/*Optional*/

/* Custom number box configuration */

input[type="number"] {
  width: 0.395833rem;
  height: 0.1875rem;
  background-color: rgba(5,45,82,0.4);
  border: 0.010417rem solid #ffffff;
  border-radius: 0.020833rem;
  color: #fff;
  font-size: 0.104167rem;
  padding: 0 0.052083rem;
}

input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"]+div {
  width: 0.15625rem;
  height: 0.208333rem;
  padding-left: 0.010417rem;
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 0;
}