.company {
  width: 100%;
}

.company .aboutUs {
  width: 100%;
  height: 3.958333rem;
  position: relative;
}

.company .aboutUs .btn {
  cursor: pointer;
  position: absolute;
  width: 1.322917rem;
  height: 0.348958rem;
  top: 2.760417rem;
  left: 1.864583rem;
}

.company .partner {
  width: 100vw;
  height: 4.6875rem;
  background: url("~@/assets/img_wc/bg-img-1.png") no-repeat center/cover;
}

.company .partner>.title {
  padding-top: 0.708333rem;
  text-align: center;
  font-size: 0.25rem;
  font-weight: bold;
  line-height: 0.25rem;
  letter-spacing: 0em;
  color: #ffffff;
  margin-bottom: 0.682292rem;
}

.company .partner .imgBox {
  width: 6.213542rem;
  margin: 0 auto;
}

.company .partner .imgBox>div {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.380208rem;
}

.company .partner .imgBox>div img {
  cursor: pointer;
  width: 0.46875rem;
}

.link {
  color: #1890ff !important;
}

.link:link {
  color: #1890ff;
}

.link:hover {
  color: #1890ff;
}