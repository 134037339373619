.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 0.875rem;
  background: url(../../assets/img_wc/foot-bg.png) no-repeat center/100% 100%;
  color: #ffffff;
}

.footer .logo {
  width: 0.9375rem;
  height: 0.15625rem;
}

.footer .content {
  display: flex;
  margin-left: 0.770833rem;
}

.footer .content .copyright,
.footer .content .policy {
  padding-top: 0.041667rem;
  font-size: 0.072917rem;
  font-weight: bold;
  color: #999999;
}

.footer .content .copyright {
  margin-left: 0.770833rem;
}