.container {
  width: 100%;
}

.container .banner {
  display: flex;
}

.container .headcount {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -0.3125rem;
  padding-bottom: 0.3125rem;
  margin-bottom: 0.104167rem;
}

.container .headcount .item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 4.6875rem;
  background: #d61f38;
  padding: 0 0.1875rem 0.3125rem 0.1875rem;
  transition: height 200ms ease;
}

.container .headcount .item>.title {
  margin-top: 0.3125rem;
  font-size: 0.1875rem;
  font-weight: bold;
  color: #ffffff;
}

.container .headcount .item>.content {
  margin-top: 0.1875rem;
  font-size: 0.083333rem;
  font-weight: 400;
  color: #ffffff;
  line-height: 0.1875rem;
}

.container .headcount .item .expandableContent {
  overflow: hidden;
  transition: max-height 0.3s ease-out;
}

.container .headcount .item .expandableContent .subItem {
  margin-top: 0.15625rem;
  border-top: 0.005208rem solid rgba(255,255,255,0.4);
  transition: height 200ms ease;
}

.container .headcount .item .expandableContent .subItem>.title {
  padding: 0.1875rem 0 0.15625rem 0;
  font-size: 0.125rem;
  font-weight: 500;
  color: #ffffff;
}

.container .headcount .item .expandableContent .subItem>.content {
  font-size: 0.083333rem;
  font-weight: 400;
  color: #ffffff;
}

.container .headcount .item .expandableContent .subItem>.content ul li {
  list-style-type: circle;
  margin-left: 0.104167rem;
}

.container .headcount .item>.button {
  cursor: pointer;
  margin-top: 0.3125rem;
  width: 0.25rem;
  height: 0.25rem;
}

.container .headcount .item>.button.open {
  background: url(../../assets/img_wc/icon-open.png) no-repeat center/100% 100%;
}

.container .headcount .item>.button.close {
  background: url(../../assets/img_wc/icon-close.png) no-repeat center/100% 100%;
}

.container .contactUs {
  width: 100%;
  height: 3.489583rem;
  position: relative;
}

.container .contactUs>.button {
  cursor: pointer;
  position: absolute;
  width: 1.322917rem;
  height: 0.348958rem;
  top: 2.317708rem;
  left: 5.744792rem;
}