.container {
  position: fixed;
  width: 100%;
  top: 0;
  height: 0.46875rem;
  box-shadow: none;
}

.container .navBar {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  font-weight: 600;
  min-height: 0.46875rem;
  padding: 0 1.875rem;
}

.container .navBar.bgColor {
  background-color: #ffffff;
}

.container .navBar .logo {
  cursor: pointer;
  width: 1.15625rem;
  height: 0.229167rem;
  margin-right: 1.458333rem;
}

.container .navBar .logo img {
  width: 100%;
  height: 100%;
}

.container .navBar .tabs {
  display: flex;
  margin-top: 0.104167rem;
}

.container .navBar .tabs .text {
  cursor: pointer;
  margin-left: 0.28125rem;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container .navBar .tabs .text.specialColor {
  color: #d61f38;
}

.container .navBar .tabs .text.textColor {
  color: #000000;
}

.container .navBar .tabs .text .itemFoot {
  margin-top: 0.041667rem;
  width: 60%;
  height: 0.026042rem;
}

.container .navBar .tabs .text .itemFoot.selected {
  background: #ffffff;
}

.container .navBar .tabs .text .itemFoot.selected.footColor {
  background: #000000;
}

.container .navBar .downLoad {
  width: 0.75rem;
  height: 0.15625rem;
  display: block;
  margin-left: 0.260417rem;
  cursor: pointer;
}