.container {
  width: 100%;
}

.container .banner {
  width: 100%;
}

.container .addressContainer {
  display: flex;
  width: 100%;
  height: 4.166667rem;
}

.container .addressContainer>.address {
  background: #f1f4fb;
  width: 5.520833rem;
  height: 100%;
  padding: 0.567708rem 1.458333rem 0 1.875rem;
}

.container .addressContainer>.address .title {
  color: #d61f38;
  font-size: 0.25rem;
  font-weight: bold;
  font-weight: bold;
}

.container .addressContainer>.address .icon {
  margin-top: 0.302083rem;
  width: 0.1875rem;
  height: 0.1875rem;
}

.container .addressContainer>.address .icon.address {
  margin-top: 0.302083rem;
  width: 0.1875rem;
  height: 0.1875rem;
  background: url(../../assets/img_wc/icon-address.png) no-repeat center/100% 100%;
}

.container .addressContainer>.address .icon.email {
  margin-top: 0.302083rem;
  width: 0.1875rem;
  height: 0.1875rem;
  background: url(../../assets/img_wc/icon-email.png) no-repeat center/100% 100%;
}

.container .addressContainer>.address .subTitle {
  padding: 0.052083rem 0 0.083333rem 0;
  font-size: 0.104167rem;
  font-weight: bold;
  color: #000000;
}

.container .addressContainer>.address .content {
  font-size: 0.104167rem;
  font-weight: 400;
  color: #000000;
}

.container .addressContainer .map {
  flex: 1 1;
  height: 100%;
}